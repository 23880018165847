import { Component } from "react";
import Ligne from "../../../structure/Ligne";
import React from "react";
import { TypeLigne } from "../../../structure/TypeLigne";

interface LigneItemProps {
  ligne: Ligne;
  afficherGares: () => void;
}

export default class LigneItem extends Component<LigneItemProps> {
  render() {
    return (
      <tr className="ligne" onClick={this.props.afficherGares}>
        <td>{this.props.ligne.idExterne}</td>
        <td>{this.props.ligne.nom}</td>
        <td>{TypeLigne[this.props.ligne.type]}</td>
        <td>{this.props.ligne.couleur}</td>
        <td>{this.props.ligne.ordre}</td>
        <td></td>
      </tr>
    );
  }
}
