import Region from "../structure/Region";
import Ligne from "../structure/Ligne";
import GareDansLigne from "../structure/GareDansLigne";
import { TypeLigne } from "../structure/TypeLigne";
import { LigneFactory } from "../factory/LigneFactory";
import VersionManager from "../factory/VersionManager";
import Gare from "../structure/Gare";
import { GareDansLigneFactory } from "../factory/GareDansLigneFactory";
import { ExportedData } from "../structure/ExportedData";
import { Fond, Point } from "../structure/PointDansLigne";
import { orderByValue } from "../Tools";

export interface StateRegion {
  region: Region | null;
  versionManager: VersionManager;
  exportedData: ExportedData | null;
}

export enum TypeActionRegion {
  ajouterLigne,
  creerEtAjouterLigne,
  ajouterGareDansLigne,
  creerEtAjouterGareDansLigne,
  choisirRegion,
  getExportedData,
  ordonnerParPosition,
  faireThermometreSimple,
  inverserOrdre,
  supprimerGareDansLigne,
  supprimerGare,
  modifierGareDansLigne
}

export type ActionRegion =
  | { type: TypeActionRegion.choisirRegion; region: Region }
  | { type: TypeActionRegion.ajouterLigne; ligne: Ligne }
  | {
    type: TypeActionRegion.creerEtAjouterLigne;
    idExterne: string;
    nom: string;
    typeLigne: TypeLigne;
    couleur: string;
    ordre: number;
  }
  | {
    type: TypeActionRegion.ajouterGareDansLigne;
    gareDansLigne: GareDansLigne;
  }
  | {
    type: TypeActionRegion.creerEtAjouterGareDansLigne;
    gare: Gare;
    ligne: Ligne;
  }
  | {
    type: TypeActionRegion.getExportedData;
  }
  | {
    type: TypeActionRegion.ordonnerParPosition;
    ligne: Ligne;
  }
  | {
    type: TypeActionRegion.faireThermometreSimple;
    ligne: Ligne;
  }
  | {
    type: TypeActionRegion.inverserOrdre;
    ligne: Ligne;
  }
  | {
    type: TypeActionRegion.supprimerGareDansLigne;
    gare: Gare;
    ligne: Ligne;
  }
  | {
    type: TypeActionRegion.modifierGareDansLigne;
    gareDansLigne: GareDansLigne;
  }
  | {
    type: TypeActionRegion.supprimerGare;
    gare: Gare;
  };

export const reducerRegion: React.Reducer<StateRegion, ActionRegion> = (
  state,
  action
) => {
  switch (action.type) {
    case TypeActionRegion.choisirRegion:
      return { ...state, region: action.region };
    case TypeActionRegion.ajouterLigne:
      if (state.region === null) return state;
      state.region.addLigne(action.ligne);
      return { ...state, region: state.region };
    case TypeActionRegion.creerEtAjouterLigne:
      {
        if (state.region === null) return state;
        console.log("Création de la ligne " + action.nom);
        const ligneFactory = new LigneFactory(state.versionManager);
        const region = state.region;
        region.addLigne(
          ligneFactory.generate(
            action.idExterne,
            action.nom,
            action.typeLigne,
            action.couleur,
            action.ordre
          )
        );
        return { ...state, region };
      }
    case TypeActionRegion.ajouterGareDansLigne:
      if (state.region === null) return state;
      state.region.addGaresDansLigne(action.gareDansLigne);
      return { ...state, region: state.region };
    case TypeActionRegion.creerEtAjouterGareDansLigne:
      if (state.region === null) return state;
      console.log(
        "Ajout de la gare " +
        action.gare.nom +
        " dans la ligne " +
        action.ligne.nom
      );
      const gdlFactory = new GareDansLigneFactory(state.versionManager);
      state.region.addGaresDansLigne(
        gdlFactory.generate(action.gare, action.ligne)
      );
      return { ...state, region: state.region };
    case TypeActionRegion.getExportedData:
      if (state.region === null) return { ...state };

      const exportedData = state.region.generateExportData(
        state.versionManager.getNewNumeroVersion()
      );
      return {
        ...state,
        exportedData
      };
    case TypeActionRegion.ordonnerParPosition:
      {
        if (state.region === null) return { ...state };

        //On commence par récupérer la liste des GdL concernées
        let listeGdl = state.region.garesdansligne.filter(
          (gdl: GareDansLigne) => gdl.ligne === action.ligne && !gdl.isDeleted()
        );

        //On va les réordonner. Pour cela, on commence par connaître l'orientation de la ligne (nord-sud ou ouest-est)
        let bornes = { minLat: 90, maxLat: -90, minLong: 180, maxLong: -180 };

        listeGdl.forEach((gdl: GareDansLigne) => {
          let gare = gdl.gare;

          if (bornes.minLat > gare.latitude) bornes.minLat = gare.latitude;
          else if (bornes.maxLat < gare.latitude) bornes.maxLat = gare.latitude;

          if (bornes.minLong > gare.longitude) bornes.minLong = gare.longitude;
          else if (bornes.maxLong < gare.longitude)
            bornes.maxLong = gare.longitude;
        });

        let axeNS: boolean =
          bornes.maxLat - bornes.minLat >= bornes.maxLong - bornes.minLong;

        //On ordonne
        listeGdl = listeGdl.sort((a: GareDansLigne, b: GareDansLigne) => {
          if (axeNS) {
            // On ordonne en mettant la gare le plus au nord en premier
            return orderByValue(b.gare.latitude, a.gare.latitude);
          } else {
            //On ordonne par rapport aux latitudes, gare la plus à l'ouest en premier
            return orderByValue(a.gare.longitude, b.gare.longitude);
          }
        });

        //Enfin, on met le point qui va bien
        listeGdl.forEach(
          (gdl: GareDansLigne, i: number, list: Array<GareDansLigne>) => {
            let newOrdre = i + 1;

            if (gdl.ordre !== newOrdre)
              //On le marque comme étant modifié
              gdl.versionMaj = state.versionManager.getWorkspaceVersion();

            gdl.ordre = newOrdre;
          }
        );

        return { ...state };
      }
    case TypeActionRegion.faireThermometreSimple:
      {
        if (state.region === null) return { ...state };

        //On commence par récupérer la liste des GdL concernées
        let listeGdl = state.region.garesdansligne
          .filter(
            (gdl: GareDansLigne) => gdl.ligne === action.ligne && !gdl.isDeleted()
          );

        if (listeGdl.some(item => item.ordre !== 0)) {
          // On trie dans l'ordre si on en a un
          listeGdl = listeGdl.sort((a: GareDansLigne, b: GareDansLigne) =>
            orderByValue(a.ordre, b.ordre)
          );
        } else {
          // Sinon, on met un ordre par défaut
          listeGdl.forEach((gdl, index) => { gdl.ordre = index + 1; });
        }

        //Enfin, on met le point qui va bien
        listeGdl.forEach(
          (gdl: GareDansLigne, i: number, list: Array<GareDansLigne>) => {
            let newPoint = Point.Centre;
            let newFond: Fond;

            if (i === 0) newFond = Fond.CentreBas;
            else if (i === list.length - 1) newFond = Fond.CentreHaut;
            else newFond = Fond.CentreHautBas;

            if (gdl.pdlPoint !== newPoint || gdl.pdlFond !== newFond)
              //On le marque comme étant modifié
              gdl.versionMaj = state.versionManager.getWorkspaceVersion();

            gdl.pdlPoint = newPoint;
            gdl.pdlFond = newFond;
          }
        );

        return { ...state };
      }
    case TypeActionRegion.inverserOrdre:
      {
        if (state.region === null) return { ...state };

        //On commence par récupérer la liste des GdL concernées
        let listeGdl = state.region.garesdansligne
          .filter(
            (gdl: GareDansLigne) => gdl.ligne === action.ligne && !gdl.isDeleted()
          )
          .sort((a: GareDansLigne, b: GareDansLigne) =>
            orderByValue(a.ordre, b.ordre)
          );

        let maxOrdre = 0;
        listeGdl.forEach((gdl: GareDansLigne) =>
          gdl.ordre > maxOrdre ? (maxOrdre = gdl.ordre) : null
        );

        //On réordonne
        listeGdl.forEach((gdl: GareDansLigne, idx: number) => {
          let newOrdre = maxOrdre - idx;
          if (gdl.ordre !== newOrdre)
            gdl.versionMaj = state.versionManager.getWorkspaceVersion();
          gdl.ordre = newOrdre;
        });

        return { ...state };
      }
    case TypeActionRegion.supprimerGareDansLigne:
      if (state.region === null) return { ...state };

      //On va chercher la relation en question
      let gdl = state.region.garesdansligne.find(
        (item: GareDansLigne) =>
          item.ligne.idExterne === action.ligne.idExterne &&
          item.gare.idExterne === action.gare.idExterne
      );

      if (gdl === undefined) return { ...state };

      console.log(
        "Suppression de gdl avec gare = " +
        action.gare.idExterne +
        " & ligne = " +
        action.ligne.idExterne
      );

      //Deux solutions, soit la relation existe déjà, et on note sa suppression, sinon, la supprime tout simplement
      if (!gdl.versionCreation.existe)
        state.region.garesdansligne.slice(
          state.region.garesdansligne.indexOf(gdl),
          1
        );
      else {
        gdl.versionCreation = state.versionManager.getNoneVersion();
        gdl.versionMaj = state.versionManager.getNoneVersion();
        gdl.versionSuppression = state.versionManager.getWorkspaceVersion();
      }
      return { ...state, region: state.region };
    case TypeActionRegion.modifierGareDansLigne:
      if (state.region === null) return { ...state };

      //On va chercher la relation en question
      let gareDansLigne = state.region.garesdansligne.findIndex(
        (item: GareDansLigne) =>
          item.ligne.idExterne === action.gareDansLigne.ligne.idExterne &&
          item.gare.idExterne === action.gareDansLigne.gare.idExterne
      );

      if (gareDansLigne === -1) return { ...state };

      state.region.garesdansligne[gareDansLigne].ordre =
        action.gareDansLigne.ordre;
      state.region.garesdansligne[gareDansLigne].pdlFond =
        action.gareDansLigne.pdlFond;
      state.region.garesdansligne[gareDansLigne].pdlPoint =
        action.gareDansLigne.pdlPoint;
      state.region.garesdansligne[
        gareDansLigne
      ].versionMaj = state.versionManager.getWorkspaceVersion();

      return { ...state, region: state.region };
    case TypeActionRegion.supprimerGare:
      if (state.region === null) return { ...state };

      console.log("Suppression de la gare " + action.gare.idExterne);

      //Deux solutions, soit la gare existe déjà, et on note sa suppression, sinon, la supprime tout simplement. Puis on cascade aux relations
      if (!action.gare.versionCreation.existe) {
        state.region.gares.slice(state.region.gares.indexOf(action.gare), 1);

        //En toute logique, la gare n'ayant pas d'existence dans la BDD, ses relations non plus
        state.region.garesdansligne
          .filter(
            (gdl: GareDansLigne) => gdl.gare.idExterne === action.gare.idExterne
          )
          .forEach((gdl: GareDansLigne) => {
            if (state.region === null) return;

            state.region.garesdansligne.slice(
              state.region.garesdansligne.indexOf(gdl),
              1
            );
          });
      } else {
        action.gare.versionCreation = state.versionManager.getNoneVersion();
        action.gare.versionMaj = state.versionManager.getNoneVersion();
        action.gare.versionSuppression = state.versionManager.getWorkspaceVersion();

        //Et on cascade aux relations
        state.region.garesdansligne
          .filter(
            (gdl: GareDansLigne) => gdl.gare.idExterne === action.gare.idExterne
          )
          .forEach((gdl: GareDansLigne) => {
            gdl.versionCreation = state.versionManager.getNoneVersion();
            gdl.versionMaj = state.versionManager.getNoneVersion();
            gdl.versionSuppression = state.versionManager.getWorkspaceVersion();
          });
      }
      return { ...state, region: state.region };
    default:
      throw new Error("Action inconnue");
  }
};
