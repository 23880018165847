import GareDansLigne from "../../../structure/GareDansLigne";
import React from "react";
import { Map, Popup, TileLayer, Polyline, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./leaflet-container.css";
import {
  CarteDesGaresCommunProps,
  CarteDesGaresCommun
} from "./CarteDesGaresCommun";

interface CarteDesGaresDansLigneProps extends CarteDesGaresCommunProps {
  gares: Array<GareDansLigne>;
}

export class CarteDesGaresDansLigne extends CarteDesGaresCommun<
  CarteDesGaresDansLigneProps
> {
  render() {
    const couleurLigne = this.props.gares[0].ligne.couleur;
    console.log("couleur ligne", couleurLigne);
    return (
      <div className="leaflet-container">
        <Map
          center={
            this.props.gares
              .filter((gdl: GareDansLigne) => !gdl.isDeleted())
              .map((gdl: GareDansLigne): [number, number] => [
                gdl.gare.latitude,
                gdl.gare.longitude
              ])
              .reduce((acc, item) => [acc[0] + item[0], acc[1] + item[1]])
              .map((value: number) => value / this.props.gares.length) as [
              number,
              number
            ]
          }
          zoom={12}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Polyline
            positions={this.props.gares.map((gdl: GareDansLigne): [
              number,
              number
            ] => [gdl.gare.latitude, gdl.gare.longitude])}
            color={couleurLigne}
            opacity={0.6}
            weight={8}
          />
          {this.props.gares
            .filter((gdl: GareDansLigne) => !gdl.isDeleted())
            .map((gdl: GareDansLigne) => {
              let gare = gdl.gare;
              let position: [number, number] = [gare.latitude, gare.longitude];
              return (
                <CircleMarker key={gare.idExterne} radius={4} center={position} color={couleurLigne} fill={true} fillColor={"white"} fillOpacity={1} weight={2}>
                  <Popup>
                    <b>Nom</b>
                    <br />
                    {gare.nom}
                    <br />
                    <b>Ordre</b>
                    <br />
                    {gdl.ordre}
                  </Popup>
                </CircleMarker>
              );
            })}
        </Map>
      </div>
    );
  }
}
