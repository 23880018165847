import React from "react";
import { ExportedData } from "../../../structure/ExportedData";
import autobind from "autobind-decorator";
import { ActionRegion, TypeActionRegion } from "../../ActionRegion";

interface ExportButtonProps {
  data: ExportedData | null;
  dispatcherRegion: React.Dispatch<ActionRegion>;
}

export default class ExportButton extends React.Component<ExportButtonProps> {
  @autobind
  genererFichierUrl() {
    this.props.dispatcherRegion({ type: TypeActionRegion.getExportedData });
  }

  render() {
    let urlFichierLignes: string = "";
    let urlFichierGares: string = "";
    let urlFichierGdl: string = "";

    if (this.props.data !== null) {
      const base64UrlLignes = btoa(
        unescape(encodeURIComponent(this.props.data.fileLignes))
      );
      const base64UrlGares = btoa(
        unescape(encodeURIComponent(this.props.data.fileGares))
      );
      const base64UrlGdl = btoa(
        unescape(encodeURIComponent(this.props.data.fileGaresDansLigne))
      );
      urlFichierLignes = "data:text/csv;base64," + base64UrlLignes;
      urlFichierGares = "data:text/csv;base64," + base64UrlGares;
      urlFichierGdl = "data:text/csv;base64," + base64UrlGdl;
    }

    return (
      <div>
        <button onClick={this.genererFichierUrl}>Génerer fichiers</button>
        {urlFichierGares !== "" && (
          <span>
            <a href={urlFichierGares} download="Gares.csv">
              Gares.csv
            </a>{" "}
            −{" "}
          </span>
        )}
        {urlFichierLignes !== "" && (
          <span>
            <a href={urlFichierLignes} download="Lignes.csv">
              Lignes.csv
            </a>{" "}
            −{" "}
          </span>
        )}
        {urlFichierGdl !== "" && (
          <span>
            <a href={urlFichierGdl} download="GaresDansLigne.csv">
              GaresDansLigne.csv
            </a>
          </span>
        )}
      </div>
    );
  }
}
