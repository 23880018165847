import { Component } from "react";
import { Fond, Point } from "../../../structure/PointDansLigne";
import React from "react";

interface AfficherPointProps {
  fond: Fond;
  point: Point;
}

export default class AfficherPoint extends Component<AfficherPointProps> {
  render() {
    return (
      <div>
        {Fond[this.props.fond]} - {Point[this.props.point]}
      </div>
    );
  }
}
