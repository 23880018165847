import React, { Component } from "react";
import LigneItem from "./LigneItem";
import Ligne from "../../../structure/Ligne";
import AjouterLigneForm from "./AjouterLigneForm";
import { ActionRegion } from "../../ActionRegion";

interface AfficherLignesProps {
  lignes: Array<Ligne>;
  onSelectedLigne: (ligne: Ligne) => () => void;
  dispatcherRegion: React.Dispatch<ActionRegion>;
}

export default class AfficherLignes extends Component<AfficherLignesProps> {
  render() {
    return (
      <div>
        <h2>Lignes</h2>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nom</th>
              <th>Type</th>
              <th>Couleur</th>
              <th>Ordre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.lignes.map((ligne: Ligne) => (
              <LigneItem
                ligne={ligne}
                key={ligne.idExterne}
                afficherGares={this.props.onSelectedLigne(ligne)}
              />
            ))}
          </tbody>
        </table>
        <AjouterLigneForm dispatcherRegion={this.props.dispatcherRegion} />
      </div>
    );
  }
}
