export enum Fond {
  Vide = 0,
  CentreHaut = 1,
  GaucheHaut = 2,
  DroiteHaut = 3,
  GaucheDroiteHaut = 4,
  CentreBas = 10,
  CentreHautBas = 11,
  GaucheDroiteHautCentreBas = 14,
  GaucheBas = 20,
  Gauche = 22,
  GaucheDroiteHautGaucheBas = 24,
  DroiteBas = 30,
  DroiteHautBas = 33,
  DroiteGaucheHautDroiteBas = 34,
  GaucheDroiteBas = 40,
  CentreHautGaucheDroiteBas = 41,
  GaucheHautGaucheDroiteBas = 42,
  DroiteHautGaucheDroiteBas = 43,
  GaucheDroiteHautGaucheDroiteBas = 44,
}

/*
d↓\u→ 0     1     2     3     4
---------------------------------
    [XXX] [ | ] [|  ] [  |] [|_|]
 0  [XXX] [   ] [   ] [   ] [   ]
    [XXX] [   ] [   ] [   ] [   ]
---------------------------------
    [   ] [ | ] [XXX] [XXX] [|_|]
 1  [   ] [ | ] [XXX] [XXX] [ | ]
    [ | ] [ | ] [XXX] [XXX] [ | ]
---------------------------------
    [   ] [XXX] [|  ] [XXX] [| |]
 2  [   ] [XXX] [|  ] [XXX] [|  ]
    [|  ] [XXX] [|  ] [XXX] [|  ]
---------------------------------
    [   ] [XXX] [XXX] [  |] [| |]
 3  [   ] [XXX] [XXX] [  |] [  |]
    [  |] [XXX] [XXX] [  |] [  |]
---------------------------------
    [   ] [ | ] [|  ] [  |] [| |]
 4  [ _ ] [ ⊥ ] [|  ] [  |] [| |]
    [| |] [| |] [| |] [| |] [| |]

*/

export enum Point {
  Vide = 0,
  Centre = 1,
  CentreSensBas = 2,
  CentreSensHaut = 3,
  Droite = 11,
  DroiteSensBas = 12,
  DroiteSensHaut = 13,
  Gauche = 21,
  GaucheSensBas = 22,
  GauncheSensHaut = 23,
}

/*
d↓\u→ 1     2     3
---------------------
    [   ] [   ] [   ]
 0  [ * ] [↓* ] [ *↑]
    [   ] [   ] [   ]
---------------------
    [   ] [   ] [   ]
 1  [  *] [ ↓*] [ ↑*]
    [   ] [   ] [   ]
---------------------
    [   ] [   ] [   ]
 2  [*  ] [*↓ ] [*↑ ]
    [   ] [   ] [   ]
*/
